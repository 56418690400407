import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import { API_URL_NEW } from '../lib/endpoints'
import { prepareFilterParam, prepareSelectParam } from '../lib/queryParams'
import { ALLOWED_QUERY_OPERATOR, FUNDING_STATUS } from '../lib/constants';
import { sortObject } from '/src/lib/helpers'

export default {

    deleteProductPicture: (startupId, fileId) => {
        let url = `${API_URL_NEW}/startup/${startupId}/product-file/${fileId}`

        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then(async (res) => {
                    resolve(res.data.data)
                }).catch(err => reject(err))
        })
    },

    getPendingFundingRequestsData: (startupId, page, limit) => {
        const fields = prepareSelectParam(['id', 'currency', 'funding_stage_data', 'investors', 'investedin_data'])
        let url = `${API_URL_NEW}/startup/${startupId}/funding-request?fields=${fields}&page=${page}&limit=${limit}`

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(async (response) => {
                    if (response.data.data.length > 0) {
                        resolve({
                            records: response.data.data,
                            count: response.data.navigation.total
                        })
                    } else {
                        resolve({
                            records: [],
                            count: 0
                        })
                    }
                }).catch(err => {
                    console.log("ERROR: ", err)
                    reject(err)
                })


        })
    },

    // get total startups
    getStartupsCount: () => {
        return new Promise((resolve, reject) => {
            let url = `${API_URL_NEW}/startup/setting/count?filters=status^:1`;
            axios.get(url).then(async (response) => {
                if (response.data.status === 200) {
                    resolve(response.data.data);
                } else {
                    let error = new Error(response);
                    throw error;
                }
            }).catch(err => {
                let data = {
                    error: err,
                    // set static value in case of error
                    totalCount: "15,000"
                }
                console.log("ERROR: ", err);
                reject(data);
            })
        })
    },

    getRecentlyFundedStartups: (limit) => {
        return new Promise((resolve, reject) => {
            let url = `${API_URL_NEW}/startup?fields=id^,code^,name^,hq_data^,logo_data^,primary_industry_data^,last_date_raised^,last_amount_raised&limit=${limit}&sort=last_date_raised^:DESC`;
            axios.get(url).then(async (response) => {
                if (response.data.status === 200) {
                    resolve(response.data.data);
                } else {
                    let error = new Error(response);
                    throw error;
                }
            }).catch(error => {
                console.log("ERROR: ", error);
                reject(error);
            })
        })
    },

    getStartupTeamInfo: (startupId, getFields, getFilters) => {
        let fields = prepareSelectParam(getFields)
        let filters = prepareFilterParam(getFilters)
        let url = `${API_URL_NEW}/startup/${startupId}/team?fields=${fields}&filters=${filters}`

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                console.log("ERROR: ", err)
                reject(err)
            })
        })
    },

    addStartupIndustry: (startupId, payload) => {
        let url = `${API_URL_NEW}/startup/${startupId}/industry`;

        return new Promise((resolve, reject) => {
            axios.post(url, payload)
                .then((response) => {
                    if (response.data.status === 201) {
                        resolve(response.data.data);
                    }
                }).catch(error => {
                    reject(error);
                })
        })
    },

    getGeoFocusLocations: (startupId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/geo-focus?fields=id^,location_data&limit=300`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data)
                }).catch(err => {
                    reject(err)
                })
        });
    },

    addStartupLocation: (startupId, payload) => {
        let url = `${API_URL_NEW}/startup/${startupId}/geo-focus`;

        return new Promise((resolve, reject) => {
            axios.post(url, payload)
                .then((response) => {
                    if (response.data.status === 201) {
                        resolve(response.data.data);
                    }
                }).catch(error => {
                    reject(error);
                })
        })
    },

    getFollowersCount: (startupId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/follower/count`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data);
                }).catch(err => {
                    reject(err);
                })
        });
    },

    // for startups, investors, corps, enablers
    getTeamData: (startupId, entity, hasDirAccess = false, limit = "") => {
        let url = `${API_URL_NEW}/${entity}/${startupId}/team?fields=user_data^,team_role_data^,social_media_links`;
        if (hasDirAccess) {
            url += "^,email_data";
        }
        url += `&limit=${limit}`

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getBusinessType: (startupId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/business-type/?fields=business_type_id^,business_type_data`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data)
                }).catch(err => {
                    reject(err)
                })
        });
    },

    getIndustries: (startupId) => {
        const fields = prepareSelectParam(['id', 'industry_data', 'is_primary', 'is_sub_industry'])
        const url = `${API_URL_NEW}/startup/${startupId}/industry?fields=${fields}&sort=is_primary^:desc`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data)
                }).catch(err => {
                    reject(err)
                })
        });
    },

    getInstitutions: (entityId, entityType = "startup", limit = 6, page = 1) => {
        const url = `${API_URL_NEW}/${entityType}/${entityId}/institution?fields=linked_data&limit=${limit}&page=${page}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {

                    // strip duplicates
                    let institutionIDs = [];
                    let data = [];
                    response.data.data.map(v => {
                        if (!isEmpty(v.linked_data)) {
                            if (!institutionIDs.includes(v.linked_data.id)) {
                                institutionIDs.push(v.linked_data.id);
                                data.push(v);
                            }
                        }
                    })
                    resolve({
                        records: data,
                        totalRecords: response.data.navigation.total
                    });

                }).catch(err => {
                    reject(err)
                })
        });
    },

    getSupportData: (startupId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/support?fields=*`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data)
                }).catch(err => {
                    reject(err)
                })
        });
    },

    getSimilarStartups: (startupId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/similar?limit=6`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data)
                }).catch(err => {
                    reject(err)
                })
        });
    },

    getStartupBenchmarking: (startupId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/benchmarking?limit=3`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data)
                }).catch(err => {
                    reject(err)
                })
        });
    },

    getAdditionalStartupBenchmarking: (startupId, fetchId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/benchmarking/${fetchId}`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data)
                }).catch(err => {
                    reject(err)
                })
        });
    },

    getUserFollowStatus: (startupId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/user-follow-status`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve(response.data.data)
                }).catch(err => {
                    reject(err)
                })
        });
    },

    getInvestors: (entityId, entityType = "startup", limit = 6, page = 1, getFilters = []) => {

        let filterQuery = '';
        if (getFilters && getFilters.length > 0) {
            filterQuery = `filters=${prepareFilterParam(getFilters)}&`;
        }
        const url = `${API_URL_NEW}/${entityType}/${entityId}/investor?${filterQuery}limit=${limit}&page=${page}`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    resolve({
                        records: response.data.data,
                        totalRecords: response.data.navigation.total
                    });
                }).catch(err => {
                    reject(err)
                })
        });
    },

    // for startups, enablers, etc.
    getProductFiles: (entityType, id) => {
        const fields = prepareSelectParam(['product_file_data']);
        const url = `${API_URL_NEW}/${entityType}/${id}/product-file/?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data);
            }).catch(err => {
                reject(err)
            })
        });
    },

    closeStartup: (startupId, payload) => {
        const url = `${API_URL_NEW}/startup/${startupId}`;

        return new Promise((resolve, reject) => {
            axios.patch(url, payload)
                .then((response) => {
                    if (response.data.status === 200) {
                        resolve(response.data.data);
                    }
                }).catch(error => {
                    reject(error);
                });
        })
    },

    getStartup: ({ fields, filters, sortParams, pagniationParams }) => {
        const updatedFilters = filters.concat('^,status^:1'); // default status filter
        const url = `${API_URL_NEW}/startup?fields=${fields}&filters=${updatedFilters}&sort=${sortParams}&${pagniationParams}`
        return axios.get(url)
    },

    // get startup investments
    getStartupInvestments: (id, limit, getFilters) => {
        const fields = prepareSelectParam(['funding_id', 'funding_stage_data', 'invested_amount', 'investedin_data', 'total_amount', 'date_raised', 'investors_data', 'created_at', 'funding_breakdowns_data', 'valuation']);

        //  add mandatory filter to exclude draft funding rounds, draft: verification_status = 3
        const arrFilters = [...getFilters, { key: 'verification_status_filter', value: [FUNDING_STATUS.draft], op: ALLOWED_QUERY_OPERATOR.notEq }];
        const filters = prepareFilterParam(arrFilters);

        const url = `${API_URL_NEW}/startup/${id}/invested?fields=${fields}&filters=${filters}&limit=${limit}&sort=date_raised^:DESC`;

        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                if (response.data.status === 200) {

                    let rounds = response.data.data

                    // ignore invalid test data
                    let cleanRounds = [];
                    rounds.map((item, i) => {
                        if (item.investedin_data) {
                            cleanRounds.push(item);
                        }
                    })

                    resolve({
                        rounds: cleanRounds,
                        totalRecords: response.data.navigation.total
                    });

                }
            }).catch((err) => reject(err));
        });
    },

    getFunding: (id) => {
        const fields = prepareSelectParam(['id', 'amount_raised', 'post_money_valuation', 'funding_stage', 'funding_stage_data', 'investedin_data', 'funding_breakdowns_data', 'investors_data']);
        const URL = `${API_URL_NEW}/startup/${id}/funding?fields=${fields}&sort=date_raised^:DESC`;

        return new Promise((resolve, reject) => {
            axios.get(URL).then(response => {
                response = response.data.data;
                if (response && response.length > 0) {
                    let rounds = [];
                    response.map(v => {

                        // change parameter names to expected values
                        if (!isEmpty(v.funding_stage_data)) {
                            v.funding_stage_id = v.funding_stage_data.id;
                        }
                        v.breakdown = v.funding_breakdowns_data;

                        // delete temp properties
                        delete v.funding_breakdowns_data;
                        delete v.funding_stage_data;

                        v.breakdown.map((brk, i) => {
                            brk.investors = [];
                            brk.investors_data.map((inv, j) => {
                                let tempInv = {
                                    invested_amount: inv.amount,
                                    entity_id: inv.id,
                                    entity_code: inv.code,
                                    entity_type: inv.type,
                                    entity_name: inv.name
                                }
                                brk.investors.push(tempInv);
                            })
                            delete brk.investors_data; // delete temp prop
                        })

                        // sort breakdowns chronologically
                        // and give the round date_raised of the latest breakdown
                        v.breakdown   = sortObject(v.breakdown, "date_raised");
                        v.date_raised = v.breakdown[0].date_raised;
                        rounds.push(v);

                    });

                    rounds = sortObject(rounds, "date_raised");
                    resolve({ rounds });

                }
            }).catch((err) => reject(err));
        });
    },

    // accept a join funding request
    acceptJoinFundRequest: (startupId, fundingBreakdownId, invFundingId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/funding-breakdown/${fundingBreakdownId}/investor-funding/${invFundingId}/accept-join-request`;

        const payload = {
            status: 1
        };

        return new Promise((resolve, reject) => {
            axios.patch(url, payload)
                .then((response) => {
                    resolve(response.data.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    // reject a join funding request
    rejectJoinFundRequest: (startupId, fundingBreakdownId, invFundingId) => {
        const url = `${API_URL_NEW}/startup/${startupId}/funding-breakdown/${fundingBreakdownId}/investor-funding/${invFundingId}/reject-join-request`;

        const payload = {
            status: 3
        };

        return new Promise((resolve, reject) => {
            axios.patch(url, payload)
                .then((response) => {
                    resolve(response.data.data);
                }).catch(error => {
                    reject(error);
                })
        })
    },

    /* get top 10 funded startup for directory pdf/ppt export */
    getTopFundedStartups: (filters) => {

        // constants
        const page   = 1;
        const limit  = 10;
        const sort   = `total_fund^:desc`;
        const fields = prepareSelectParam(['name', 'logo_data', 'primary_industry_data', 'hq_data', 'date_founded', 'fund_raised_data', 'number_of_rounds_raised']);
        
        let url = `${API_URL_NEW}/startup?fields=${fields}&filters=${filters}&page=${page}&limit=${limit}&sort=${sort}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                resolve(response)
            }).catch(err => {
                if (!axios.isCancel(err)) {
                    reject(err)
                }
            })
        })

    }

}
